export default {
  helpers: {
    state: {
      clients: {
        blank: {
          content: 'Get a single source of truth for all client details along with the history of emails exchanged, internal notes, files and tasks. Get started by creating a client manually or importing your clients using a CSV file.',
          title: 'Manage your clients all in one place.'
        },
        companies_house: {
          syncing_loading: 'Update in progress...',
          syncing_email: 'This may take a few minutes. You can close this window.<br>When the synchronisation finishes you will receive a notification.'
        },
      },
    },
    clients: {
      import_csv: {
        modal_results: {
          state: {
            blank: 'Data imported successfully.'
          },
          error: 'Any rows containing errors were not imported. To resolve the errors, you must correct them in the CSV file and retry the data import.',
          title: 'Import results',
          imported_feedback: '{{ total_errors }} error(s) found while processing a total of {{ total }} rows. ',
          show_all: 'Show all data'
        },
        actions: {
          clients: {
            title: '1. Import client records',
            description: `You can bring your existing clients from any other software into ${window.applicationName} by importing a CSV file with their details.`
          },
          contacts: {
            title: '2. Import client contacts',
            description: 'Once you have imported the client records, you can import and associate contacts with those clients.'
          }
        },
        prepare_file: {
          title: 'How to prepare your file',
          description: `Coming to ${window.applicationName} from Excel to Google Sheets? The guide below will walk you though how to prepare your files for a successful import.`,
          action: 'View import guide'
        },
        download_file: {
          title: 'Download sample files',
          description: 'Looking to import your clients? Download the template files and ensure there are no surprises during the import process.',
          action: 'Download sample spreadsheet'
        },
        questions: {
          title: 'Have questions?',
          description: 'Migrating data should be easy. Read the answers to all your questions about data security, file types, and troubleshooting.',
          action: 'View the FAQ'
        }
      }
    },
    client_tasks: {
      actions: {
        delete: 'Delete client task',
        mark_done: 'Mark as completed'
      },
      modals: {
        autoreminder: {
          title: 'Configure the auto-reminder',
          info: 'Auto-reminders will be sent a maximum 4 times. Otherwise, the messages may end up in the spam folder.'
        },
        share: {
          title: 'Sharing options',
          note: 'The pass-phrase allow your client to open the Client task portal.</br>You can always find this pass-phrase and change it in <a class=\'u-ts-4 u-color-secondary u-clear-border\' href=\'{{ url }}\'>Client Details</a>',
          link_label: 'Shareable link:',
          pass_label: 'Passphrase:',
          copy_title: 'Copy to clipboard',
        },
        email_writer: {
          title: 'Send task list to client'
        },
        confirm: {
          delete: 'Are you sure you want to delete this client task?',
          complete: 'Are you sure you want to mark this client task as completed?'
        }
      },
      notifier_options: {
        resend_email: 'Re-send email',
        share: 'Sharing details',
        autoreminder: 'Configure auto-reminders',
      },
      notice: {
        auto_reminder_sent: 'Auto-reminders sent to client',
        sent_to: 'Sent to client on {{ last_sent }}',
        sending_interval: 'every {{ word }}',
        copied: 'Copied.',
        copy_error: 'Copy failed.',
        delete_error: 'Client task was not deleted.'
      },
      instruction_edit: {
        notice: {
          updated: 'Client tasks instruction updated.',
          error: 'Error updating client tasks instruction.',
          confirmation: 'Are you sure you want to perform this action? You will lose all the changes you have made so far.'
        },
        placeholders: {
          editor: 'Click to add more details to this subtask...'
        }
      },
      define_autoreminder: 'Set auto-reminder',
      send_to_client_button: {
        save_title: 'Please save the task content first.',
        save: 'Send to client',
        resend: 'Ask client to recheck again',
        edit: 'Edit task list'
      }
    },
    chat: {
      title: 'Conversation',
    },
    integrations: {
      btc_software: {
        short_description: `Allows you to import tax returns into ${window.applicationName} and have clients electronically sign.`,
        long_description: `Our integration with BTCSoftware allows you to import clients and tax returns from BTCHub. You can then send completed tax returns for electronic signing without leaving ${window.applicationName}. Please note: only individual tax returns are supported at this time. <br><br>To utilise this integration, you must have an active BTC Hub account. To enable this integration you will be redirected to authenticate with your BTC Hub login details. You will then be able to import clients and any in-progress tax returns.`,
        enable_integration: 'Enable BTCSoftware integration',
        disable_integration: 'Disable BTCSoftware integration',
        matches_to: 'Matches to',
        setup_completed: 'Setup completed',
        ready_to_start: 'You’re ready to start using the BTCSoftware integration.',
        go_to_work: 'Go to Work',
        import_work: 'Import Work',
        tax_returns: 'tax returns',
      },
      companies_house: {
        name: 'Companies House',
        company_number: {
          title: '<b>1. Company registration number</b> is used to match clients with Companies House.'
        },
        date_details: {
          title: `<b>2. Companies House key dates</b> that you want to import into ${window.applicationName}.`
        },
        long_description: 'This integration synchronises key dates on client records with Companies House.<br><br>If the option you want is not available, you can add it using <a href={{link}} class="u-color-blue u-hover-link" target="_blank">Settings - Clients custom fields</a>.'
      }
    },
    placeholder: {
      job_template: {
        assignee: 'Select default assignee',
        email_placeholders: 'Placeholders',
        email_recipient: 'Select email recipient',
        email_sender: 'Select email sender',
        field: 'Select field',
        instructions: 'Insert instructions',
        month: {
          day: {
            position: 'On the',
            unit: 'Day',
          },
        },
        period: 'Choose a period',
        scope: 'Choose an option',
        search: 'Search template name...',
        tax_year: 'Tax Year',
        template_name: 'Template name...',
        type: 'Select task type',
      },
    },
    email: {
      sidebar: {
        titles: {
          delegated_by: 'Delegated by {{name}}',
          linked_to_task: 'Linked to task',
          mark_as_done: 'Mark as done',
          move_to_inbox: 'Move to inbox',
          restore_thread: 'Restore thread',
          delete_thread: 'Delete thread'
        },

        syncing: 'Syncing...',
      },
      thread: {
        header: {
          task_deadline: 'Task deadline',
          completed: 'Completed',
          go_to_opened_task: 'View open task',
          go_to_completed_task: 'View closed task',
          unlink_task: 'Unlink task',
          link_clients_to_email: '+ Associate email with client',
          associated_clients: 'Associated clients',
          remove_all: 'Clear all',
          create_task_from_email: 'Create task from email',
          link_email_to_task: 'Add email to existing task',
          add_new_client: '+ Create client',
          log_email_in_existing_client: '+ Associate email with client',
          reassigned_email_to: '{{ delegator }} reassigned this email to <span class="u-tw-3 u-ml-tiny">{{ delegate }}</span>'
        },

        email: {
          reassign: 'Reassign',
          reply_to_everyone: 'Reply to everyone',
          forward: 'Forward',
          click_to_view_attachment_page: 'Open secure document sharing page'
        },

        messages: {
          select_from_list: {
            desc: 'Select a conversation from the list on the left to view its contents.',
          },
          no_priority_messages: {
            title: 'No more messages in the Priority inbox.',
            desc: 'The priority inbox helps you stay focused, showing only messages from existing clients. Everything else can be found in the non-priority inbox.',
          },
          no_messages_to_handle: {
            title: 'No messages left to handle.',
            desc: `When ${window.applicationName} is unable to match a new message to a client, it will appear in this non-priority inbox.`,
          },
          nothing_in_archives: {
            title: 'Nothing in the archives yet.',
            desc: 'To keep your inbox tidy, mark a message as done to move it to the archive.',
          },
          no_messages_have_been_sent: {
            title: 'No messages have been sent yet.',
            desc: `When you send a message, either through ${window.applicationName} or your email app, it will show up here.`,
          },
          no_deleted_messages: {
            title: 'No messages have been deleted yet.',
            desc: `Deleting a message in ${window.applicationName} archives - but does not delete - the message in your actual email inbox.`,
          },
          no_reassigned_messages: {
            title: 'No messages have been reassigned.',
            desc: 'You can reassign messages to anyone on your team to let them handle it. Reassigned messages will appear in their Priority inbox.',
          },
          no_messages_for_job: {
            title: 'No messages logged for this job yet.',
            desc: 'Messages exchanged about this job will show up here. This allows everyone to get a better understanding of what has been discussed with the client.',
          },
          no_messages_for_client: {
            title: 'No messages logged for this client yet.',
            desc: 'Messages exchanged with the client will show up here, allowing everyone to get a better understanding of what has been discussed so far.',
          }
        },

        email_server_error: `An email server error occurred in this conversation. Please contact ${window.applicationName} support.`,
        click_to_reply: 'Click here to reply...',
        attachments: 'Attachments',
      },
      modal: {
        remove_from_associated: 'Are you sure you want to remove the client from the associated list?',
        remove_all_from_associated: 'Are you sure you want to remove all clients from the associated list?',
        empty_trash: 'Are you sure you want to all the messages from the trash? ',
        cancel: 'Cancel',
        confirm: 'Confirm',
        remove: 'Remove',
        reassign: 'Reassign',
        reassign_email: 'Reassign email',
        reassign_to: 'Reassign to',
        link_to_task: 'Link to task',
        task: 'Task',
        client: 'Client',
        deadline: 'Deadline',
        no_tasks_available: 'No tasks available',
        no_assigned_tasks_to_you: 'There are no tasks currently assigned to you.',
        create_task: 'Create task',
        create_task_with_template: 'Create task with template',
        add_email_to_client: 'Add email to client',
        add: 'Add',
        search_client_name: 'Search client name...',
        create_client: 'Create client',
        close_dialog_window: 'Close this dialog window',
        list_of_client: 'Clients',
        no_results: 'There are no results.',
        search: 'Click to search...',
        clients: 'Clients',
      }
    },
    job: {
      common: {
        not_selected: 'None selected',
      },

      modal: {
        list_of_client: 'Clients',
        list_of_assignee: 'Assignees',
        list_of_labels: 'Task status',
        remove_client_from_job: 'Remove client',

        see_template_in_use: 'View template in use',
        save_as_template: 'Save as template',
        save_as_template_add_subtask: 'Save as template (Add subtask first)',
        apply_template: 'Apply template',
        advanced_task_editor: 'Advanced task editor',
        change_shedule: 'Change shedule',
        make_recurring: 'Make recurring',
        make_recurring_assigne_client: 'Make recurring (Assign client first)',
        delete: 'Delete',
        assignees: 'Assignees',
        team: 'Team',
        selected_labels: 'Selected labels',
        available_labels: 'Available labels',
        search: 'Search',
        uncompleted_subtasks: 'Uncompleted subtasks',
        subtasks_still_uncompleted: 'subtasks are still uncompleted.',
        are_you_sure: 'Are you sure you want to mark as done?'
      },

      client: {
        open_client_page: 'Open client page'
      },

      reopen_task: 'Reopen task',
      mark_as_done: 'Mark as done',
      reassign: 'Reassign',
    },
    audit_logs: {
      condition: 'Only show logs matching all of these conditions'
    },
    client_fields: {
      client_custom_fields: {
        delete: 'Delete field'
      }
    },
    client_types: {
      client_type_fields: {
        delete: 'Delete type'
      }
    },
    contact_properties: {
      contact_fields: {
        delete: 'Delete field'
      }
    },
    custom_field_groups: {
      custom_fields: {
        delete: 'Delete group'
      }
    },
    custom_tags: {
      custom_tags_field: {
        delete: 'Delete tag'
      }
    }
  },
  labels: {
    tax: {
      headers: {
        status: 'Status',
        payment_due: 'Payment due',
        due_date: ' Due date',
        end_date: 'End date',
        form_type: 'Form type',
        taxable_income: 'Taxable Income/(Loss)',
        tax_estimates: 'Est. Tax Payable/Refundable',
        link: 'Access Tax',
        view: 'View',
        force_refresh_tax: 'Force refresh tax',
        last_updated: 'Last updated'
      },
      buttons: {
        open_in_tax: 'Open in Tax',
        open_in_ledger: 'Open in Ledger',
      },
      tippy: {
        header: 'Estimates',
        taxable_income: 'Taxable Income',
        taxable_loss: 'Taxable Loss',
        tax_payable: 'Est tax payable',
        tax_refundable: 'Est tax refundable'
      },
      rows: {
        prefix: {
          payable: 'Payable',
          refundable: 'Refundable'
        }
      }

    },
    common: {
      view: 'View',
      yes: 'Yes',
      no: 'No'
    },
    editor: {
      styles: {
        normal: 'Normal text size',
        heading_1: 'Heading 1',
        heading_2: 'Heading 2',
        heading_3: 'Heading 3',
        heading_4: 'Heading 4'
      },
      actions: {
        undo: 'Undo',
        redo: 'Redo',
        placeholders: 'Placeholders',
        bold: 'Bold',
        horizontal_rule: 'Horizontal Rule',
        italic: 'Italic',
        underline: 'Underline',
        strikethrough: 'Strikethrough',
        text_styles: 'Text styles',
        text_color: 'Text color',
        background_color: 'Background color',
        bulleted_list: 'Bulleted list',
        numbered_list: 'Numbered list',
        todo_list: 'Todo list',
        quote: 'Quote',
        image: 'Insert image',
        video: 'Insert video',
        indent_left: 'Decrease indent',
        indent_right: 'Increase indent',
        link: 'Insert link'
      },
      errors: {
        invalid_video: 'Please enter a valid Youtube or Loom link.',
        invalid_link: 'Please enter a link URL'
      },
      modals: {
        insert_video: {
          title: 'Insert new video',
          note: 'Copy and paste the video URL. Youtube, Vimeo and Loom URLs are supported.',
          placeholder: 'Enter video URL'
        }
      }
    },
    clients: {
      import: {
        column_without_name: 'Column without name',
        client: 'Client',
        contact: 'Contact',
        select_field_name_placeholder: 'Select field name',
        client_attributes: 'Client attributes',
        client_name: 'Client name',
        client_email: 'Client email',
        client_type: 'Client type',
        client_manager: 'Client manager',
        client_role: 'Role assignee',
        ignore: 'Ignore',
        client_address_line_1: 'Client address line 1',
        client_address_line_2: 'Client address line 2',
        client_address_line_3: 'Client address line 3',
        client_city: 'Client city',
        client_state: 'Client state',
        client_postcode: 'Client postcode',
        client_country: 'Client country',
        client_custom_field: 'Client custom field',
        contact_attributes: 'Contact attributes',
        contact_full_name: 'Contact full name',
        contact_first_name: 'Contact first name',
        contact_last_name: 'Contact last name',
        contact_email: 'Contact email',
        contact_is_primary_contact: 'Is primary contact',
        contact_phone_number: 'Contact phone number',
        contact_greeting: 'Contact greeting',
        contact_job_title: 'Contact task title',
        contact_notes: 'Contact notes',
        contact_custom_field: 'Contact custom field',
        placeholder_select_custom_field: 'Select custom field',
        select_or_type_new: 'Select or type new',
        select_field_type: 'Select field type',
        modal_title: 'Import data'
      },
      bulk_actions: {
        common: {
          amount_selected: 'clients selected',
          select_all: 'Select all',
          unselect_all: 'Unselect all',
          clients: 'Clients'
        },
        update: {
          modal_title: 'Update client property',
          modal_subtitle: 'The following update will affect the',
          amount_to_update: 'selected clients',
          update_property: 'Update property',
          update_button: 'Update',
          action_prefix: 'Update',
          action_suffix: 'to',
          property_placeholder: 'Choose property'
        },
        archive: {
          archive: 'Archive',
          unarchive: 'Unarchive',
          confirm_archive: 'Are you sure you want to archive {{amount}} clients?',
          confirm_unarchive: 'Are you sure you want to archive {{amount}} clients?',
          open_tasks_amount: 'These clients have {{amount}} open tasks.'
        },
        send_email: {
          send_email: 'Send new email',
          client_without_email: 'Some clients do not have an email address defined and will not receive this message.'
        },
        new_task: {
          new_task: 'Create task'
        },
        schedule_task: {
          schedule_task: 'Schedule task'
        }
      },
      companies_house: {
        button: '+ Add from Companies House',
        tooltip: 'View in Companies House',
        placeholder: 'Company name or registration number',
        modal_title: 'Add client from Companies House',
        modal_subtitle: `Search a company name or registration number on Companies House and add them to ${window.applicationName}. ` +
          'Details such as client name, address, key dates and director information will be added.',
        results_subtitle: 'Top {{length}} matches for \'{{term}}\'',
        add_client: 'Add client',
        select_client: 'Select client',
        syncWith: 'Synchronize with Companies House'
      },
      inline_editing: {
        custom_fields: 'CUSTOM FIELDS',
        show_empty_fields: 'Show empty fields',
        address: {
          modal_title: 'Edit address',
          modal_subtitle: '',
          success_message: 'The address is updated',
          error_message: 'Cannot save the client details:<br>{{errors}}',
          labels: {
            line1: 'Address',
            line2: 'Address Line 2',
            line3: 'Address Line 3',
            post_town: 'City',
            suburb: 'Suburb',
            state: 'State',
            postcode: 'Postcode',
            country: 'Country'
          }
        },
        client_type: {
          modal_title: 'Edit client type',
          modal_subtitle: '',
          success_message: 'The client type is updated',
          error_message: 'Can\'t save the client:<br>{{errors}}',
          labels: {
            client_type: 'Client type'
          }
        },
        prospect: {
          modal_title: 'Edit prospect',
          modal_subtitle: '',
          success_message: 'The prospect is updated',
          error_message: 'Can\'t save the client:<br>{{errors}}',
          labels: {
            prospect: 'Prospect',
          }
        },
        industry: {
          modal_title: 'Edit Industry',
          modal_subtitle: '',
          success_message: 'The industry is updated',
          error_message: 'Can\'t save the client:<br>{{errors}}',
          labels: {
            industry: 'Industry',
          }
        },
        internal_reference: {
          modal_title: 'Edit internal reference',
          modal_subtitle: '',
          success_message: 'The internal reference is updated',
          error_message: 'Can\'t save the client:<br>{{errors}}',
          labels: {
            internal_reference: 'Client internal reference',
          }
        },
        custom_field: {
          modal_title: 'Edit {{field}}',
          modal_subtitle: '',
          success_message: '{{field}} is updated',
          error_message: 'Can\'t save the client:<br>{{errors}}'
        }
      }
    },
    saved_filters: {
      dropdown_placeholder: 'Select filter',
      edit_button: 'Edit filter',
      save_button: 'Save filter',
      clear_button: 'Clear',
      showing_new: 'Showing {{amount}} results.',
      showing_saved: '{{name}}: {{amount}} results.',
      new: {
        modal_title: 'Filter clients list',
        modal_subtitle: 'NOnly show clients matching all of these conditions:',
        save_button: 'Apply filter'
      },
      create: {
        modal_title: 'Save new filter',
        modal_subtitle: 'Name this filter to make it easier to find later',
        name_placeholder: 'Filter name',
        name_blank: 'Can\'t be blank',
        creation_success: '{{name}} is saved',
        creation_error: 'Can\'t save the filter:<br>{{errors}}',
        save_button: 'Save'
      },
      update: {
        modal_title: 'Edit filter',
        name_placeholder: 'Filter name',
        name_blank: 'Can\'t be blank',
        update_success: '{{name}} is saved',
        update_error: 'Can\'t save the filter:<br>{{errors}}',
        save_button: 'Save'
      },
      index: {
        modal_title: 'Manage saved filters',
        modal_subtitle: 'These are the filters available in your workspace',
        manage_button: 'Manage saved filters',
        delete_button: 'Delete',
        edit_button: 'Edit',
        no_filters: 'No saved filters found',
        deletion_confirmation: 'Do you really want to delete {{amount}} filters?',
        deletion_success: 'Filters list is updated',
        deletion_error: 'Something went wrong'
      }
    },
    tasks: {
      description: 'Description',
      edit_description: 'Edit description',
      placeholder_task_description: 'Click "Edit" to add more details to this subtask...',
    },
    emails: {
      remove_from_task: 'Remove email from task',
      add_to_task: 'Add email to task',
      review_email: 'Review email',
      task_title: 'New task from {{email}}'
    },
    actions: {
      view: 'View',
      import_clients: 'Import Clients',
      import_contacts: 'Import Contacts',
      cancel: 'Cancel',
      save: 'Save',
      save_task: 'Save task',
      saving_task: 'Saving...',
      insert: 'Insert',
      search: 'Search',
      remove: 'Remove',
      disable: 'Disable',
      update: 'Update',
      activate: 'Activate',
      previous: 'Previous',
      next: 'Next',
    },
    job_templates: {
      days_before_job_deadline: 'Days before deadline',
      weeks_before_job_deadline: 'Weeks before deadline',
      months_before_job_deadline: 'Months before deadline',
      days_after_job_starts: 'Days after task starts',
      weeks_after_job_starts: 'Weeks after task starts',
      months_after_job_starts: 'Months after task starts',
      frequency_no_repeat: 'No repeat',
      frequency_daily: 'Daily',
      frequency_weekly: 'Weekly',
      frequency_monthly: 'Monthly',
      frequency_yearly: 'Yearly',
      task_number: 'Task {{number}}',
      validations: {
        fixed_deadline: 'Fixed deadline cannot be older than the start date',
        fixed_deadline_missing: 'Deadline is required'
      },
      placeholders: {
        email_composer: {
          dear: 'Dear...'
        },
        request_signature: {
          insert_internal_note: 'Insert internal note',
          insert_subject: 'Insert subject',
          insert_message: 'Insert message',
        },
        btc_self_assessment: {
          insert_internal_note: 'Insert internal note',
          insert_approval_subject: 'Insert approval subject',
          insert_approval_message: 'Insert approval message',
          insert_confirmation_subject: 'Insert approval subject',
          insert_confirmation_message: 'Insert approval message',
          select_tax_year: 'Select Tax Year'
        }
      }
    },
    email: {
      blank: {
        subject: '(No subject)'
      },
      sidebar: {
        switcher: {
          priority: 'Priority',
          received: 'Received',
          other: 'Other',
          sent: 'Sent',
        },

        messages_in_trash: '{{count}} in trash.',
        empty: 'Empty trash',
        clear_priority: 'Clear priority inbox',
        clear_not_priority: 'Clear other inbox',
        archive_confirm_message: 'This will archive all your {{type}} email. Continue?'
      },
    },
    modals: {
      common: {
        close_title: 'Close this dialog window'
      },
      insert_video: {
        title: 'Insert new video',
        note: 'Copy and paste the video URL. Youtube, Vimeo and Loom URLs are supported.',
        placeholder: 'Enter video URL'
      }
    },
    tippy: {
      insert_video: 'Insert video',
      insert_image: 'Insert image'
    },
    integrations: {
      companies_house: {
        company_reg_no: 'Company registration number',
        incorporated_on: 'Incorporated on',
        next_accounts_due_by: 'Next accounts due by',
        next_accounts_made_up: 'Next accounts made up to',
        next_statement_date: 'Next confirmation statement date',
        next_statement_due_by: 'Next confirmation statement due by',
        options_placeholder: 'No custom field selected',
      }
    },
    otp: {
      steps: {
        step_one: 'Step 1',
        step_two: 'Step 2',
        scan_qr_code: 'Scan qr code',
        open_authenticator_app: 'Open your authenticator app.',
        add_account_to_app_by_scanning_qr: 'Add your account to the app by scanning this QR code.',
        verify_code: 'verify the code',
        enter_code_found_in_authenticator_app: 'Enter the 6-digit code found in your authenticator app',
        setup_completed: 'Setup completed',
        describe_steps_for_next_login: 'Next time you login, you’ll need to open your authenticator app and use the 6-digit code to authenticate your login.'
      },

      actions: {
        cancel: 'Cancel',
        enter_code: 'Enter Code',
        previous: 'Previous',
        verify: 'Verify',
        done: 'Done'
      },

      successful_verification: 'The code you provided was valid',
      verification_failed: 'The code you provided was invalid'
    },
    service: {
      override_template_settings: 'Override Template Settings',
    }
  },
  notices: {
    email: {
      message_sent: 'Message sent.',
      sending_error: 'Error sending the email message.',
      reading_error: 'Error reading the email message.',
      email_reassigned_back: 'Email reassigned back to you.',
      email_reassigned: 'Email reassigned.',
      email_reassigned_failed: 'Failed to reassign message.',
      trash_empited: 'Trash folder emptied.',
      trash_empited_failed: 'Failed to empty Trash folder.',
      syncronisation_complete: 'Inbox syncronisation complete.',
      thread_archived: 'Email thread archived.',
      thread_restore_failed: 'Failed to restore email thread.',
      thread_archived_failed: 'Failed to archive email thread.',
      thread_moved: 'Email thread moved to inbox.',
      restore_archived_thread_failed: 'Failed to restore archived email thread.',
      thread_restored: 'Email thread restored.',
      thread_delete_failed: 'Failed to delete email thread.',
      restore_deleted_thread_failed: 'Failed to restore deleted email thread.',
      thread_removed: 'Email thread removed.',
      removed_from_job: 'Email removed from job.',
      removed_from_job_failed: 'Failed to remove email from job.',
      email_added_to_job: 'Email added to job.',
      email_added_to_job_failed: 'Failed to add email to job.',
      email_added_to_client: 'Email added to client.',
      email_add_to_client_failed: 'Failed to add email to client.',
      load_email_messages_failed: 'Error loading email messages.',
      client_linked: 'Client linked.',
      client_link_failed: 'Failed to unlink client.',
      client_unlinked: 'Client unlinked.',
      client_unlink_failed: 'Failed to unlink client.',
      clients_unlinked: 'Clients unlinked.',
      clients_unlink_failed: 'Failed to unlink clients.',
      inbox_archived: '{{type}} emails archived.',
      inbox_not_archived: '{{type}} emails were not archived.',
      attachment_saved: 'Attachment saved.',
      attachment_save_failed: 'Failed to save attachment.',
      attachments_saved: 'Attachments saved.',
      attachments_save_failed: 'Failed to save attachments.',
      save_attachment_to_job: 'Save attachment to job',
      save_attachment_to_client: 'Save attachment to client'
    },
    task: {
      task_created: 'Task created.'
    },
    client: {
      add_from_coho: {
        generic_error: 'Error creating company.',
        duplicate_company_name: 'Duplicate company name found.',
        not_found: 'Company is not found on Companies House.',
        validation_error: 'Validation error:<br>{{errors}}',
      }
    },
    integrations: {
      companies_house: {
        activation_error: 'Error updating integration.',
        deactivation_error: 'Error disabling integration.'
      }
    },
    file_preview: {
      copyLink: 'Copy link',
      copiedToClipboard: 'Link copied to clipboard.',
      loading_error: 'Error loading document.'
    }
  },
  section: {
    client: {
      one: 'Client',
    },
    job: {
      header: {
        due_by: 'Due by',
        closed_by: 'Closed by',
        assignee: 'Assignee',
        labels: 'Task status'
      },

      modals: {
        auto_reminder: {
          title: 'Configure the auto-reminder',
          submit: 'Set auto-reminder',
          close: 'Close',

          send_reminder_every: 'Send a reminder every',
          days_until_task_is_completed: 'days until task is completed.',
          maximum_number: 'Up to 4 reminders'
        },

        add_new_contact: {
          add_new_contact: 'Add New Contact',
          submit_labels: '+ Add new contact',
          name: 'Name',
          last_name: 'Last Name',
          email: 'E-mail'
        },

        select_documents: {
          title: 'Select documents to sign',
          task_files: 'Task Files',
          client_files: 'Client files',
          upload: 'Upload',
          search: 'Search...',
          file_name: 'File name',
          task_name: 'Task name',
          upload_date: 'Upload date',
          select: 'Select',
          drag_and_drop_files: '<h1 class="u-ts-2 u-tw-3">Drag and drop</h1><div class="u-ts-3 u-mt-medium u-color-gris-light">your files, or <span class="u-color-gris-dark u-bb-dark">browse to upload</span></div>',
          drag_and_drop_pdfs: '<h1 class="u-ts-2 u-tw-3">Drag and drop</h1><div class="u-ts-3 u-mt-medium u-color-gris-light">your PDF documents, or <span class="u-color-gris-dark u-bb-dark">browse to upload</span></div>',
          files_uploading: 'Files uploading...',

          submit: 'Add documents'
        }
      },

      btc_software: {
        common: {
          assignee: 'Assignee',
          deadline: 'Deadline'
        },

        address: 'Address',
        approved_and_signed_by_the_client: 'Approved and signed by the client',
        company_name: 'Company name',
        company_number: 'Company number',
        complete_in_btc_software: 'Complete in BTCSoftware',
        complete_the_tax_return: 'Complete the tax return',
        confirmation_email_sent: 'Confirmation email sent',
        create_tax_return: 'Create tax return',
        due_by: 'Due by',
        failed_to_assign_tax_return: 'Failed to assign Tax Return',
        failed_to_load_tax_returns: 'Failed to load Tax Returns',
        first_payment: 'First payment',
        got_approval_on: 'Got approval on',
        incorporation_date: 'Incorporation date',
        ir_mark: 'IR Mark',
        loading: 'Loading...',
        match_client: 'Match client',
        no_btc_software_clients_available: 'No BTCSoftware clients available',
        no_tax_returns_for_selected_client_for_this_tax_year: 'There are no tax returns for the selected client for this tax year',
        open_in_btc_software: 'Open in BTCSoftware',
        payment_on_account: 'Payment on account',
        request_client_sign_off: 'Request client sign off',
        review_email_and_send: 'Review email and send',
        self_assessment: 'Self Assessment',
        select_client_first: 'Select a client for this task first',
        select_client_in_btc_software: 'Select client in BTCSoftware',
        select_existing_tax_return: 'Select existing tax return',
        select_tax_return: 'Select Tax Return',
        send_confirmation_email_to_client: 'Send confirmation email to client',
        sent_on: 'Sent on',
        sent_to_client: 'Sent to client',
        start_new_tax_return: 'Start new tax return',
        status: 'Status',
        submit_to_hmrc_via_btc_software: 'Submit to HMRC via BTCSoftware',
        submit_to_hmrc: 'Submit to HMRC',
        submitted_to_hmrc: 'Submitted to HMRC',
        tax_return_completed: 'Tax return completed',
        tax_return_created: 'Tax return created',
        tax_return: 'Individual Tax Return',
        tax_year: 'Tax Year',
        type: 'Type',
        year: 'Year',
      },

      one: 'Task'
    },
    job_templates: {
      index: {
        title: 'Task templates',
        add_template: '+ Add template',
        import_template: 'Import template',
        template_library: 'Template library',
        search_placeholder: 'Search...',
        blank: {
          content: 'Use task templates to capture the sequence of tasks ' +
            'necessary to complete a task or an internal process. ' +
            'Your team will always know what needs to be done and when.',
          title: 'Put your practice on auto-pilot.',
        },
        table_headers: {
          name: 'Template name',
          schedule: 'When to start',
          recurring: 'Repeating',
          deadline: 'Deadline',
          assignee: 'Default assignee',
          labels: 'Task status',
          actions: ''
        },
        actions: {
          edit: 'Edit template',
          new_task: 'Create task from template',
          clone: 'Clone template',
          export: 'Export',
          share: 'Share',
          share_error: 'Share action failed.',
          delete: {
            confirmation: {
              zero: 'Do you really want to delete {{name}}?</h1>' +
                '<p>This task template will be deleted permanently</p><h1>&nbsp;',
              singular: 'Do you really want to delete {{name}}?</h1>' +
                '<p>This task template is associated with <b>{{count}} client</b>. ' +
                'Should you choose to continue, the task template will be removed ' +
                'and new tasks will not be scheduled. Ongoing tasks won’t be affected.' +
                '</p><h1>&nbsp;',
              plural: 'Do you really want to delete {{name}}?</h1>' +
                '<p>This task template is associated with <b>{{count}} clients</b>. ' +
                'Should you choose to continue, the task template will be removed ' +
                'and new tasks will not be scheduled. Ongoing tasks won’t be affected.' +
                '</p><h1>&nbsp;'
            },
            error: 'Something went wrong.',
            success: '{{name}} deleted.',
            title: 'Delete'
          },
          archive: {
            confirmation: {
              zero: 'Do you really want to archive {{name}}?</h1>' +
                '<p>This task template will be archived</p><h1>&nbsp;',
              singular: 'Do you really want to archive {{name}}?</h1>' +
                '<p>This task template is associated with <b>{{count}} client</b>. ' +
                'Should you choose to continue, the task template will be archived ' +
                'and new tasks will not be scheduled. Ongoing tasks will not be affected.' +
                '</p><h1>&nbsp;',
              plural: 'Do you really want to archive {{name}}?</h1>' +
                '<p>This task template is associated with <b>{{count}} clients</b>. ' +
                'Should you choose to continue, the task template will be archived ' +
                'and new tasks will not be scheduled. Ongoing tasks won’t be affected.' +
                '</p><h1>&nbsp;'
            },
            error: 'Something went wrong.',
            success: '{{name}} archived.',
            title: 'Archive'
          },
          restore: {
            confirmation: 'Do you really want to restore {{name}}?</h1>' +
              '<p>This task template will be restored</p><h1>&nbsp;',
            error: 'Something went wrong.',
            success: '{{name}} restored.',
            title: 'Restore'
          }
        },
        modal: {
          filter_task_templates: 'Filter task templates',
          filter_description: 'Only show templates matching all of these conditions',
          cancel: 'Cancel',
          confirm: 'Confirm',
          aplly_filter: 'Apply filter',
          confirm_description: 'Do you want to apply the newly added tags to all jobs (open and closed)?'
        }
      }
    },
    job_template: {
      assignee: 'Default assignee',
      assignee_not_set: 'Assignee not set',
      no_job_template_suffix: 'None',
      client_manager: 'Client manager',
      deadline: 'Deadline',
      details: 'Template details',
      frequency: 'Task recurrence',
      label: 'Task template',
      shared: 'Shared template',
      subtasks: 'Subtasks',
      tasks: 'Tasks',
      trigger: 'When to start the task',
    },
    clients: {
      linked_clients: {
        title: 'Linked clients',
        add_linked_clients: '+ Add linked client',
        add_linked_client: 'Add linked client',
        edit_linked_client: 'Edit linked client',
        company: 'Client',
        relationship: 'Label',
        cancel: 'Cancel',
        save: 'Save'
      }
    },
    audit_logs: {
      index: {
        filter: {
          user: 'User',
          category: ' Category',
          client: 'Client',
          date_interval: 'Date Interval',
          apply_filter: 'Apply filter'
        }
      }
    },
    letters_of_engagement: {
      title: 'Letters of engagement',

      table_title: 'Letters of Engagement templates',
      client_table_title: 'Letters of Engagement',
      create_template: '+ Create template',
      create_loe: '+ Letter of engagement',
      no_data: 'No data to display',
      success_message: 'Template is {{ action }}',
      error_message: 'Failed to {{ action }} template. {{ reason }}',
      labels: {
        loe_name: 'LOE name',
        loe_template: 'LOE Template',
        last_updated_on: 'Last updated on',
        last_updated_by: 'Last updated by',
        status: 'Status',
        sections: 'Sections',
        signers: 'Signers',
        client: 'Client',
        days: 'days',
        document: 'Document name',
        signed_on: 'Signature',
        date_sent: 'Date sent'
      },

      bulk_actions: {
        clone: 'Clone',
        edit: 'Edit',
        download_pdf: 'Download PDF',
        preview_pdf: 'Preview PDF',
        generating_pdf: 'Generating PDF...',
        publish: 'Publish',
        unpublish: 'Unpublish',
        archive: 'Archive',
        unarchive: 'Unarchive',
        delete: 'Delete',
        rename: 'Rename'
      },

      template_dialog: {
        title: 'Letter of engagement template',
        name: 'Document name',
        total: 'Total',
        address: 'Firm address',
        logo: 'Firm Logo',
        ignore_logo: 'Ignore Logo',
        add: '+ Add section',
        section: 'Section',
        section_name: 'Section title',
        required: 'Should this section always be included in the LOE?',
        section_text: 'Section text',
        remove: 'Remove',
        cancel_section: 'Cancel',
        save_section: 'Save section',
        save: 'Save draft',
        letter_date: 'Date of letter',
        reference: 'Reference'
      },
      dialog: {
        title: 'Letter of engagement',
        should_include: 'Should this section be included in this LOE?',
        is_required: 'This section is required and cannot be removed.',
        remind: 'Automatically send a reminder until this is signed, cancelled or expired'
      },
      error_dialog: {
        name: 'Please fill out the document name.',
        template: 'Please fill out the document template.',
        message: 'Please fill out the email message.',
        subject: 'Please fill out the email subject.',
        address: 'Please fill out the firm address.',
        reference: 'Please fill out the reference',
        signers: 'Please select the signers.',
        client: 'Please select the client.'
      },
      status: {
        not_sent: 'Not sent',
        sent: 'Sent',
        cancelled: 'Cancelled',
        expired: 'Expired',
        draft: 'Draft',
        signed: 'Signed'
      }
    },
    qbopm_importing: {
      importing_finished: 'We have finished importing your clients\' records. <a href="/onboard_importing" class="u-link">Go to Importing</a>',
      importing_in_progress: 'We are importing your clients\' records from QBOA for you to check.',
      storing_finished: 'We have finished storing your clients\' records. <a href="/clients" class="u-link">Go to Clients page</a>',
      storing_in_progress: 'We are storing your clients\' records in Practice Manager'
    },
    qbopm_onboarding: {
      import_match: 'Import Match',
      no_data: 'No data',
      no_match: 'No match, use QuickBooks data',
      added: 'Added',

      labels: {
        billing_address: 'Billing address',
        client: 'Client',
        company_house_match: 'Company house match',
        company_type: 'Company type in QBOA',
        email: 'Email',
        company_number: 'Company number',
        nature_of_business: 'Nature of business',
        next_account: 'Next account',
        next_statement: 'Next statement'
      },

      actions: {
        add_from_ch: 'Add from CH',
        back: 'Back',
        remove: 'Remove',
        cancel: 'Cancel matching',
        continue: 'Continue',
        next: 'Next',
        import: 'Check and import'
      },

      notifications: {
        fetch: {
          error: 'Failed to load clients.',
        },

        update: {
          success: 'Client was updated.',
          error: 'Failed to update client.',
        },

        update_clients: {
          success: 'Clients were updated.',
          error: 'Failed to reject clients.',
        },

        import: {
          error: 'Failed to import clients.',
        },
      },
    },
    report: {
      select_report: 'Select report',
      create_new_report: 'Create new report',
      add_new_widget: 'Add new widget',
      clone_this_report: 'Clone this report',
      edit_this_report: 'Edit this report',
      sync_this_report: 'Sync this report',
      delete_this_report: 'Delete this report',
      edit_widget: 'Edit widget',
      delete_widget: 'Delete widget',
      email: 'Email',
      total_emails_received: 'Total Emails Received',
      total_emails_sent: 'Total Emails Sent',
      average_respond_time: 'Average Time to Respond',
      no_data: 'Nothing to display.',
      no_data_desc: 'You do not have any reports created. You can create the report using the create button.',
      users: 'Users',
      sections_to_include: 'Sections to include',
      timeframe: 'Timeframe',
      aggregation: 'Aggregation',
      job_templates: 'Job templates',
      client_types: 'Client types',
      clients: 'Clients',
      tags: 'Task status',

      tooltip: {
        count_of_jobs_per_status: '${point.y} jobs per tag',
        count_of_jobs_per_deadline: '${point.y} tasks',
        timelines: '${point.y}%',
        number_of_clients_per_employee: '${point.y} clients',
        number_of_clients_in_quickbooks_skus: 'Value: ${point.y}',
        count_of_clients_per_template: '${point.y} clients',
      },

      timeframes: {
        this_week: 'This Week',
        this_month: 'This Month',
        last_week: 'Last Week',
        last_month: 'Last Month',
        last_year: 'Last Year'
      },

      dialogs: {
        add_widget: {
          title: 'Add widget',
        },

        edit_widget: {
          title: 'Edit widget',
        },

        add_report: {
          title: 'Create Report',
        },

        edit_report: {
          title: 'Edit Report',
        },
      },

      cancel: 'Cancel',
      add_widget: 'Add widget',
      create_report: 'Create report',
      edit_report: 'Edit report',

      notifications: {
        reports: {
          fetch: {
            error: 'Failed to load reports.'
          }
        },

        report: {
          fetch: {
            error: 'Failed to load report.'
          },

          create: {
            success: 'Report was created.',
            error: 'Failed to create report.',
          },

          edit: {
            success: 'Report was edited.',
            error: 'Failed to edit report.'
          },

          sync: {
            success: 'Report was synced.',
            error: 'Failed to sync report.'
          },

          delete: {
            confirmation: 'Are you sure you want to delete this report?',
            success: 'Report was deleted.',
            error: 'Failed to delete report.'
          }
        },

        widget: {
          create: {
            success: 'Widget was created.',
            error: 'Failed to create widget.',
          },

          edit: {
            success: 'Widget was edited.',
            error: 'Failed to edit widget.'
          },

          delete: {
            confirmation: 'Are you sure you want to delete this widget?',
            success: 'Widget was deleted.',
            error: 'Failed to delete widget.'
          }
        }
      }
    }
  },
  ui: {
    common: {
      filter: 'Filter',
      clear: 'Clear'
    }
  },
  document_signing: {
    auto_reminder_client_notice: 'Auto-remind signers every <strong>{{ reminderValue }} days</strong> until document is signed.',
    request_signature: 'Request signature',
    document_signing: 'Document signing',
    declined_on_date: 'Declined on {{ date }}',
    signed_on_date: 'Signed on {{ date }}',
    not_sent_yet: 'Not sent yet',
    error_loading_files: 'Error loading files.',
    email_address_required: 'Email address required.',
    draft_saved: 'Draft saved.',
    error_saving_draft: 'Error saving draft.',
    contact_added: 'Contact added',
    error_adding_contact: 'Error adding contact.',
    reminder_enabled: 'Reminder enabled.',
    error_enabling_reminder: 'Error enabling reminder.',
    files_uploaded: 'File(s) uploaded.',
    files_added: 'File(s) added.',
    error_uploading_files: 'Error uploading file(s).',
    task_cancelled: 'Task cancelled.',
    error_cancelling_task: 'Error cancelling task.',
    reminder_sent: 'Reminder sent.',
    error_sending_reminder: 'Error sending reminder.',
    file_removed: 'File removed.',
    error_removing_file: 'Error removing file.'
  }
};
